<template>
  <a-modal
    width="650px"
    class="excluded-area-geographic-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :closable="true"
    :visible="true"
    @cancel="onExit">
    <h3 class="flex align-center txt-32 txt-bold">
      {{ $t('components.titles.identifyExcludedArea') }}
    </h3>
    <p>
      {{ $t('components.description.enterRadius') }}
    </p>
    <a-input-number
      v-model.trim="payload.radius"
      class="mb-20 w100"
      :disabled="isSupervisor"
      :placeholder="$t('components.description.excludedRadius')"
      @change="onRadiusChange"/>
    <div class="map-container">
      <l-map
        ref="myMap"
        class="map"
        :zoom="zoom"
        :center="center"
        @ready="ready"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
        @click="onClick">
        <v-tilelayer-googlemutant
          :apikey="GOOGLE_MAPS_API_KEY"
          lang="en"
          region="en"/>
        <l-circle
          :draggable="!isSupervisor"
          :lat-lng="payload.center"
          :radius="payload.radius * 1000 || 1000"
          :color="payload.color"
          @update:latLng="onMove"/>
      </l-map>
    </div>
    <div class="flex justify-space-between w100">
      <a-button
        class="mt-24 w170"
        type="primary"
        ghost
        size="large"
        @click="onExit">
        {{ $t('values.cancel') }}
      </a-button>
      <a-button
        class="mt-24 w170"
        type="primary"
        size="large"
        :disabled="isSupervisor"
        @click="save">
        {{ $t('values.save') }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex';
import { latLng } from 'leaflet';
import { LMap, LCircle } from 'vue2-leaflet';
import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant';
import { GOOGLE_MAPS_API_KEY } from '../../../../../util/map';

export default {
  name: 'ExcludeGeographicModal',
  components: {
    LMap,
    LCircle,
    'v-tilelayer-googlemutant': Vue2LeafletGoogleMutant
  },
  props: {
    onExit: {
      type: Function,
      required: true
    },
    initialLat: {
      type: Number,
      default: 23
    },
    initialLng: {
      type: Number,
      default: 79
    }
  },
  data() {
    return {
      payload: {
        radius: undefined,
        center: latLng(this.initialLat, this.initialLng),
        color: '#e98300'
      },
      GOOGLE_MAPS_API_KEY,
      zoom: 12,
      center: latLng(this.initialLat, this.initialLng),
      url: 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'
    };
  },
  computed: {
    ...mapState({
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    })
  },
  created() {
    if (this.initialLat && this.initialLng) {
      this.center = latLng(this.initialLat, this.initialLng);
      this.payload.center = latLng(this.initialLat, this.initialLng);
    }
  },
  methods: {
    onRadiusChange(value) {
      if (this.isSupervisor) {
        return;
      }
      try {
        if (value <= 0) {
          this.payload.radius = undefined;
        }
      } catch (e) {
        // Do nothing
      }
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
      this.$refs.myMap.mapObject._onResize();
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    ready() {
      this.$refs.myMap.mapObject._onResize();
    },
    async onMove({ lat, lng }) {
      if (this.isSupervisor) {
        return;
      }
      this.payload.center = latLng(lat, lng);
    },
    save() {
      if (this.isSupervisor) {
        return;
      }
      this.$emit(
        'complete',
        `${this.payload.radius || 1} KM around ${this.payload.center.lat.toFixed(5)}, ${this.payload.center.lng.toFixed(5)}`
      );
    },
    onClick(ev) {
      if (this.isSupervisor) {
        return;
      }
      this.payload.center = ev.latlng;
    }
  }
};
</script>

<style lang="scss" scoped>
.map-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 6fr;
  gap: 0px 0px;
  grid-template-areas:
    "."
    ".";
  border: solid 1px #cccccc;
  background: #f4f4f4;
  height: 375px;
}

.map {
  border-top: solid 1px #cccccc;
  overflow: auto;
  width: 100%;
  height: 100%;
}
</style>
